import { Controller } from "stimulus";
import { setButtonDisabled } from "../utilities";

export default class extends Controller {
  static targets = ["actionButton"];

  connect() {
    this.element.addEventListener("checkbox-table:selected", this.handleSelected);
  }

  disconnect() {
    this.element.removeEventListener("checkbox-table:selected", this.handleSelected);
  }

  handleSelected = (event) => {
    const { selectedIds } = event.detail;

    this.updateActionButton(selectedIds);
  };

  updateActionButton(selectedIds) {
    if (!this.hasActionButtonTarget) {
      return;
    }

    if (!selectedIds.length) {
      setButtonDisabled(this.actionButtonTarget, true);
      return;
    }

    const urlWithParams = new URL(this.actionButtonTarget.href);

    urlWithParams.searchParams.set("charge_ids", selectedIds.join(","));
    this.actionButtonTarget.href = urlWithParams.toString();

    setButtonDisabled(this.actionButtonTarget, false);
  }
}
