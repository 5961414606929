import { Controller } from "stimulus";
import IMask from "imask";

export default class extends Controller {
  static targets = ["phone"];

  connect() {
    IMask(this.phoneTarget, {
      mask: "+00 0000 0000 0000",
    });
  }
}
