import Flatpickr from "stimulus-flatpickr";

import { French } from "flatpickr/dist/l10n/fr";
import { Italian } from "flatpickr/dist/l10n/it";
import { German } from "flatpickr/dist/l10n/de";
import { Spanish } from "flatpickr/dist/l10n/es";
import { english } from "flatpickr/dist/l10n/default";

export default class extends Flatpickr {
  locales = {
    fr: French,
    it: Italian,
    de: German,
    es: Spanish,
    en: english,
  };

  connect() {
    // define locale and global flatpickr settings for all datepickers
    this.config = {
      locale: this.locale,
    };

    super.connect();
  }

  get locale() {
    if (this.locales && this.data.has("locale")) {
      return this.locales[this.data.get("locale")];
    }
    return "";
  }
}
