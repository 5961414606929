import { Controller } from "stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = ["entries", "pagination"];

  intersectionObserver = undefined;

  initialize() {
    const options = {
      rootMargin: "200px",
    };
    this.intersectionObserver = new IntersectionObserver(
      (entries) => this.processIntersectionEntries(entries),
      options,
    );
  }

  connect() {
    if (this.intersectionObserver) {
      this.intersectionObserver.observe(this.paginationTarget);
    }
  }

  disconnect() {
    if (this.intersectionObserver) {
      this.intersectionObserver.unobserve(this.paginationTarget);
    }
  }

  processIntersectionEntries(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.loadMore();
      }
    });
  }

  async loadMore() {
    const nextPage = this.paginationTarget.querySelector("a[rel='next']");
    if (nextPage == null || this.loading) {
      return;
    }
    this.loading = true;
    const url = nextPage.href;

    try {
      const response = await get(url, {
        responseKind: "json",
      });

      if (response.ok) {
        const data = await response.json;
        this.entriesTarget.insertAdjacentHTML("beforeend", data.entries);
        this.paginationTarget.innerHTML = data.pagination;
      }
    } catch (error) {
      console.error("Failed to load more entries:", error);
    } finally {
      this.loading = false;
    }
  }
}
