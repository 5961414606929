import { Controller } from "@hotwired/stimulus";
import { destroy } from "@rails/request.js";

export default class extends Controller {
  static values = {
    items: Array,
    options: Object,
    groups: Array,
    autoResize: Boolean,
    start: String,
    end: String,
  };

  async connect() {
    const { DataSet, Timeline } = await import("vis-timeline/standalone");
    await import("vis-timeline/styles/vis-timeline-graph2d.css");

    const container = this.element;
    const items = new DataSet(this.processContent(this.itemsValue));
    const hasGroups = this.hasGroupsValue && this.groupsValue.length > 0;
    const processedGroups = hasGroups ? this.processContent(this.groupsValue) : null;
    const options = this.prepareOptions();

    const timelineArgs = [container, items, options];
    if (hasGroups) {
      const groups = new DataSet(processedGroups);
      timelineArgs.splice(2, 0, groups);
    }

    this.timeline = new Timeline(...timelineArgs);
    if (this.autoResizeValue) {
      this.adjustTimelineHeight();
      window.addEventListener("resize", () => this.adjustTimelineHeight());
    }
  }

  disconnect() {
    window.removeEventListener("resize", () => this.adjustTimelineHeight());
    if (this.timeline) {
      this.timeline.destroy();
    }
  }

  adjustTimelineHeight() {
    const header = document.getElementById("berth-slots-timeline-header");
    const viewportHeight = window.innerHeight;
    const headerHeight = header.offsetHeight;
    const timelineHeight = viewportHeight - headerHeight;
    this.timeline.setOptions({ height: `${timelineHeight}px` });
  }

  processContent(dataArray) {
    return dataArray.map((data) => {
      const dataCopy = { ...data };
      if (typeof dataCopy.content === "string" && this.constructor.isHtmlString(dataCopy.content)) {
        const div = document.createElement("div");
        div.innerHTML = dataCopy.content.trim();
        dataCopy.content = div.firstChild;
      }
      return dataCopy;
    });
  }

  static isHtmlString(str) {
    return /<\/?[a-z][\s\S]*>/i.test(str);
  }

  prepareOptions() {
    const options = { ...this.optionsValue };

    if (this.hasStartValue && this.startValue.trim() !== "") {
      options.start = new Date(this.startValue);
    }

    if (this.hasEndValue && this.endValue.trim() !== "") {
      options.end = new Date(this.endValue);
    }

    if (options.editable?.remove) {
      options.onRemove = async (item, callback) => {
        try {
          const response = await destroy(`/berth_availability_slots/${item.id}`);
          if (response.ok) {
            callback(item);
            // TODO: this throws an error: TypeError: Cannot read properties of null
          } else {
            callback(null);
            alert("Failed to remove the slot. Server responded with an error.");
          }
        } catch (error) {
          console.error("Error handling slot removal:", error);
          callback(null);
          // Consider adding error handling UI feedback here once the TODO above is solved.
        }
      };
    }

    return options;
  }
}
