/* eslint-disable no-unused-expressions */
import { Controller } from "stimulus";
import tippy from "tippy.js";
import "path-data-polyfill";

const NODE_ENV = import.meta.env.MODE || "production";
const shouldLog = NODE_ENV === "development";

const DEFAULT_CLASSNAMES = "focus-visible:stroke transition-colors cursor-pointer";

const CLASSNAMES = Object.freeze({
  transit_boat: `fill-sunset-400 ${DEFAULT_CLASSNAMES}`,
  renter_boat: `fill-teal-500 ${DEFAULT_CLASSNAMES}`,
  owner_boat: `fill-teal-700 ${DEFAULT_CLASSNAMES}`,
  free_berth: `border-teal-600 ${DEFAULT_CLASSNAMES}`,
  unlisted_berth: `fill-current text-gray-400 border-gray-400 ${DEFAULT_CLASSNAMES}`,
  free_berth_available_for_subrentals: `border-teal-400 ${DEFAULT_CLASSNAMES}`,
  selected: `fill-red-800 pulse ${DEFAULT_CLASSNAMES}`,
  transparent: `fill-none hidden-path ${DEFAULT_CLASSNAMES}`,
});

export default class extends Controller {
  static targets = ["rect", "boat", "boatLayer"];

  static values = {
    berth: Object,
    mapCalendarEntry: Object,
    selected: Boolean,
    date: String,
  };

  connect() {
    this.initDOM();
    document.addEventListener("keydown", this.handleKeyDown);
  }

  disconnect() {
    this.unmountTooltip();
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  berthValueChanged() {
    this.initDOM();
  }

  mapCalendarEntryValueChanged() {
    this.initDOM();
  }

  dateValueChanged() {
    this.initDOM();
  }

  selectedValueChanged() {
    this.initDOM();
  }

  handleClick(event) {
    event.preventDefault();

    // trigger reload of the turbo-frame to the info of this sidebar
    this.sidebarTarget.src = this.sidebarURL;

    // trigger select styling
    this.unselectAllBerths();
    this.select();
  }

  handleKeyDown = (event) => {
    if (document.activeElement !== this.element) return;

    if (event.key === "Enter") {
      this.handleClick(event);
    }
  };

  // private

  initDOM() {
    this.unmountTooltip();
    this.initInteractivity();
    this.initTooltip();
    this.initRect();
    this.initBoat();
    this.initSidebar();
  }

  initInteractivity() {
    this.element.dataset.action = "click->berth-path#handleClick";
    this.element.tabIndex = "0";
    this.element.ariaLabel = this.screenReaderLabel;
    this.element.classList = this.className;
    this.element.ariaSelected = this.selectedValue;

    if (!this.path) {
      throw new Error(
        "No path found on the page. You must have a rect on the page to use the berth path feature.",
      );
    }
    if (shouldLog) {
      this.element.dataset.valueLogs = JSON.stringify({
        x: this.x,
        y: this.y,
        width: this.width,
        height: this.height,
        angle: this.angle,
        isPath: this.isPath,
      });
    }
  }

  initTooltip() {
    const berthMapElement = document.querySelector('[data-controller="berth-map"]');
    const tooltipContentTemplate = berthMapElement.dataset.berthMapTooltipContent;
    const tooltipTransitBoat = berthMapElement.dataset.berthMapTooltipTransitBoat;
    const tooltipRenterBoat = berthMapElement.dataset.berthMapTooltipRenterBoat;
    const tooltipOwnerBoat = berthMapElement.dataset.berthMapTooltipOwnerBoat;
    const tooltipFreeBerth = berthMapElement.dataset.berthMapTooltipFreeBerth;
    const tooltipUnlistedBerth = berthMapElement.dataset.berthMapTooltipUnlistedBerth;
    const tooltipFreeBerthAvailableForSubrentals = berthMapElement.dataset.berthMapTooltipFreeBerthAvailableForSubrentals;

    let berthType = "";

    const classNameToBerthType = {
      [CLASSNAMES.transit_boat]: tooltipTransitBoat,
      [CLASSNAMES.renter_boat]: tooltipRenterBoat,
      [CLASSNAMES.owner_boat]: tooltipOwnerBoat,
      [CLASSNAMES.free_berth]: tooltipFreeBerth,
      [CLASSNAMES.unlisted_berth]: tooltipUnlistedBerth,
      [CLASSNAMES.free_berth_available_for_subrentals]: tooltipFreeBerthAvailableForSubrentals,
    };

    Object.keys(classNameToBerthType).forEach((key) => {
      if (this.className.includes(key.split(" ")[0])) {
        berthType = classNameToBerthType[key];
      }
    });

    const { berthValue } = this;
    const tooltipContent = tooltipContentTemplate
      .replace("%{pontoon}", berthValue.pontoon)
      .replace("%{position_number}", berthValue.pontoon_number)
      .replace("%{type}", berthType);

    this.tippyInstance = tippy(this.element, {
      content: tooltipContent,
    });
  }

  unmountTooltip() {
    this.tippyInstance && this.tippyInstance.unmount();
  }

  initRect() {
    // replace the default path with a real rect element
    if (this.isPath) this.path.classList = CLASSNAMES.transparent;

    const path = this.isPath
      ? document.createElementNS("http://www.w3.org/2000/svg", "rect")
      : this.path;
    const el = this.hasRectTarget ? this.rectTarget : path;

    if (this.isPath) {
      el.setAttribute("x", this.x);
      el.setAttribute("y", this.y);
      el.setAttribute("width", this.width);
      el.setAttribute("height", this.height);
      el.setAttribute("transform", `rotate(${this.angle} ${this.x} ${this.y})`);
    }

    // this sets the rounded corners of the rect
    el.setAttribute("rx", "1.5");
    el.setAttribute("ry", "1.5");
    el.dataset.berthPathTarget = "rect";

    // Determine the fill color based on the class name
    let fillColor;
    if (this.className.includes("border-teal-600")) {
      fillColor = "rgba(35, 210, 202, 0.10)"; // free_berth
    } else if (this.className.includes("border-teal-400")) {
      fillColor = "rgba(35, 210, 202, 0.0)"; // free_berth_available_for_subrentals
    } else {
      fillColor = "none";
    }
    el.setAttribute("fill", fillColor);

    // Apply the border styling to make it dotted
    let strokeColor;
    if (this.className.includes("border-teal-600")) {
      strokeColor = "#319795"; // free_berth
    } else if (this.className.includes("border-teal-400")) {
      strokeColor = "#38b2ac"; // free_berth_available_for_subrentals
    } else {
      strokeColor = "#000"; // default case
    }
    el.style.stroke = strokeColor;
    el.style.strokeWidth = "0.8";
    el.style.strokeDasharray = "0.7"; // This creates a dotted effect

    // Make the element clickable by setting pointer events
    el.style.pointerEvents = "auto";
    el.style.cursor = "pointer";

    if (!el.isConnected) this.element.insertAdjacentElement("beforeend", el);
  }

  initSidebar() {
    if (!this.selectedValue) return;

    this.sidebarTarget.src = this.sidebarURL;
  }

  initBoat() {
    if (!this.boatIconTarget) {
      throw new Error(
        "No boat icon SVG found on the page. You must have a boat icon SVG on the page to use the boat feature.",
      );
    }

    if (!this.shouldShowBoat) {
      this.rectTarget.classList = "";
      this.hasBoatLayerTarget && this.boatLayerTarget.remove();
      return this.hasBoatTarget && this.boatTarget.remove();
    }

    this.rectTarget.classList = CLASSNAMES.transparent;

    const el = this.hasBoatTarget
      ? this.boatTarget
      : this.boatIconTarget.cloneNode(true);
    if (!this.hasBoatTarget) {
      // make a new layer `<g>` with this `<path>` in it
      const g = document.createElementNS("http://www.w3.org/2000/svg", "g");
      g.dataset.berthPathTarget = "boatLayer";
      const g2 = document.createElementNS("http://www.w3.org/2000/svg", "g");
      this.element.insertAdjacentElement("beforeend", g);
      g.insertAdjacentElement("beforeend", g2);
      g2.insertAdjacentElement("beforeend", el);
    }

    el.dataset.berthPathTarget = "boat";
    el.dataset.berthMapTarget = "";
    el.classList = this.className;

    const elBBox = el.getBBox();
    const flipped = /flipped/.test(this.element.id);
    const scaleX = this.shortSide / elBBox.width;
    const scaleY = ((flipped ? -1 : 1) * this.boatHeight) / elBBox.height;

    const parent = el.parentElement;
    const grandparent = parent.parentElement;

    parent.setAttribute(
      "transform",
      `
      translate(${this.x}, ${this.y})
      ${this.shouldRotate ? "rotate(90)" : ""}
      ${flipped ? `translate(0, ${this.boatHeight})` : ""}
      scale(${scaleX}, ${scaleY})
      ${
  this.shouldRotate
    ? `translate(0, ${
      (-1 * (flipped ? this.longSide : this.boatHeight)) / scaleY
    })`
    : ""
}
    `,
    );

    if (this.isPath) {
      // take the more complex approach of setting values based on calculations if using a `<path>`
      grandparent.setAttribute(
        "transform",
        `
        rotate(${this.angle} 0 0)
      `,
      );
      grandparent.setAttribute("transform-origin", `${this.x} ${this.y}`);
    } else {
      // otherwise just copy the transform property off of the `<rect>` element
      grandparent.setAttribute(
        "transform",
        this.rectTarget.getAttribute("transform"),
      );
    }

    if (shouldLog) {
      grandparent.dataset.valueLogs = JSON.stringify({
        x: this.x,
        y: this.y,
        width: this.width,
        height: this.boatHeight,
        angle: this.angle,
        shouldShowBoat: this.shouldShowBoat,
        boatHeight: this.boatHeight,
        flipped,
        scaleX,
        scaleY,
      });
    }

    return true;
  }

  /* eslint-disable class-methods-use-this */
  unselectAllBerths() {
    const berthEls = document.querySelectorAll("[data-controller=berth-path]");
    berthEls.forEach((e) => {
      e.dataset.berthPathSelectedValue = false;
    });
  }

  select() {
    this.selectedValue = true;
  }

  get path() {
    return this.element.querySelector(":is(path, rect)");
  }

  get isPath() {
    /*
    the map can either support berths marked as
    `<path>` or `<rect>` elements. this getter gives a boolean
    of which one is being used. this is important because
    it significantly modifies the calculations that need to
    be done. in general, the `<path>` calculations are
    noticeably more complex, whereas the `<rect>`
    calculations can preserve more of what's on the
    screen by default.
    */
    return Boolean(this.path.tagName.match(/path/i));
  }

  get screenReaderLabel() {
    return `Berth in pontoon ${this.berthValue.pontoon} and position number ${this.berthValue.pontoon_number}.`;
  }

  get className() {
    if (this.selectedValue) return CLASSNAMES.selected;

    return (
      CLASSNAMES[this.mapCalendarEntryValue.display_variant]
      || CLASSNAMES.free_berth
    );
  }

  /* eslint-disable class-methods-use-this */
  get sidebarTarget() {
    /*
    due to the slightly unorthodox structure with these
    controllers attaching programmatically, there wasn't
    a good way to fit this target in the stimulus controller, so
    I've just opted for a pagewide querySelector instead
    */
    return document.querySelector("#sidebar");
  }

  get sidebarURL() {
    const url = new URL("/berth_map_slots/sidebar", window.location.origin);
    url.searchParams.append("date", this.dateValue);
    url.searchParams.append("berth_id", this.berthValue.id);

    if (this.mapCalendarEntryValue?.id) {
      url.searchParams.append("slot_id", this.mapCalendarEntryValue.id);
    }

    return url.toString();
  }

  get boatIconTarget() {
    return document.querySelector("[data-berth-map-target=boat] path");
  }

  get pathData() {
    return this.path.getPathData();
  }

  get x() {
    // get all the x coords and find the min
    if (this.isPath) {
      const xCoords = this.pathData.map((d) => d.values[0]).filter(Boolean);
      return xCoords[0];
    }

    return this.path.x.baseVal.value;
  }

  get y() {
    // get all the y coords and find the min
    if (this.isPath) {
      const yCoords = this.pathData.map((d) => d.values[1]).filter(Boolean);
      return yCoords[0];
    }

    return this.path.y.baseVal.value;
  }

  get heights() {
    // calculate the heights of all 4 lines in the path
    if (!this.isPath) return [];

    return this.pathData
      .map((point1, index) => {
        const point2 = this.pathData[index + 1];
        if (!point2) return undefined;

        return Math.sqrt(
          (point1.values[0] - point2.values[0]) ** 2
            + (point1.values[1] - point2.values[1]) ** 2,
        );
      })
      .filter(Boolean);
  }

  get width() {
    /*
    calculate the height of all paths and find the min
    (we assume the retangle should be longer than it is wide)
    */
    if (this.isPath) return Math.min(...this.heights) || 0;
    return this.path.width.baseVal.value;
  }

  get height() {
    /*
    calculate the height of all paths and find the
    max (we assume the retangle should be longer than it is wide)
    but if a boat is booked in, we actually want
    to scale the height to the length of that boat!
    */
    if (this.isPath) return Math.max(...this.heights) || 0;
    return this.path.height.baseVal.value || 0;
  }

  get longSide() {
    // the long side is the side that is longer than the other two sides
    return Math.max(this.width, this.height);
  }

  get shortSide() {
    // the short side is the side that is shorter than the other two sides
    return Math.min(this.width, this.height);
  }

  get boatHeight() {
    if (!this.shouldShowBoat) return this.longSide;

    const boatLength = this.mapCalendarEntryValue.booked_boat_length_in_cm;
    const berthLength = this.berthValue.length_in_cm;
    return (boatLength / berthLength) * this.longSide;
  }

  get angle() {
    if (this.isPath) {
      const dy = this.pathData[3].values[1] - this.pathData[0].values[1];
      const dx = this.pathData[0].values[0] - this.pathData[3].values[0];
      const theta = Math.atan2(dy, dx); // range (-PI, PI]

      return 90 - (theta * 180) / Math.PI;
    }

    return (
      /* eslint-disable no-useless-escape */
      parseFloat(
        this.path
          .getAttribute("transform")
          ?.match(/rotate\((-?[\d\.]*)\)/)?.[1],
        10,
      ) || 0
      /* eslint-enable no-useless-escape */
    );
  }

  get translate() {
    if (this.isPath) {
      return [];
    }

    const matchData = this.path
      .getAttribute("transform")
      ?.match(/translate\((-?[\d.]*)\s?(-?[\d.]*)\)/);
    return matchData
      ? [parseFloat(matchData[1], 10), parseFloat(matchData[2], 10)]
      : [0, 0];
  }

  get shouldRotate() {
    /*
    sometimes if the original rectangle was of a
    different orientation than the one we're using, we
    need to rotate the boat icon
    */
    if (this.isPath) return false;
    return this.width > this.height;
  }

  get shouldShowBoat() {
    return /boat/.test(this.mapCalendarEntryValue.display_variant);
  }
  /* eslint-enable class-methods-use-this */
}
/* eslint-enable no-unused-expressions */
