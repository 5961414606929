import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["countryForm"];

  select(event) {
    if (event.currentTarget.id === "country") {
      const id = event.currentTarget.dataset.title;
      const button = document.getElementById(id);
      button.checked = true;
      if (this.hasCountryFormTarget) {
        this.countryFormTarget.submit();
      } else {
        event.currentTarget.submit();
      }
    } else if (event.currentTarget.id === "coast") {
      const id = event.currentTarget.dataset.title;
      const button = document.getElementById(id);
      button.checked = true;
      event.currentTarget.submit();
    } else if (event.currentTarget.id === "region") {
      const id = event.currentTarget.dataset.title;
      const button = document.getElementById(id);
      button.checked = true;
      event.currentTarget.submit();
    }
  }
}

// onclick="this.parentNode.submit();"
