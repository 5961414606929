import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.updateHiddenDateFields();
  }

  // eslint-disable-next-line
  updateHiddenDateFields() {
    const dateStart = document.getElementById("booking_date_start").value;
    const dateEnd = document.getElementById("booking_date_end").value;

    document.querySelector('form#booking_form input[name="booking[date_start]"]').value = dateStart;
    document.querySelector('form#booking_form input[name="booking[date_end]"]').value = dateEnd;
  }

  dateChanged() {
    this.updateHiddenDateFields();
  }
}
