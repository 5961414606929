import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["linkEn", "linkEs", "linkDe", "linkFr", "linkIt"];

  connect() {
  }

  change_locale() {
    switch (event.currentTarget.value) {
      case "en":
        this.linkEnTarget.children[0].click();
        break;
      case "es":
        this.linkEsTarget.children[0].click();
        break;
      case "de":
        this.linkDeTarget.children[0].click();
        break;
      case "fr":
        this.linkFrTarget.children[0].click();
        break;
      case "it":
        this.linkItTarget.children[0].click();
        break;
      default:
        this.linkEnTarget.children[0].click();
    }
  }
}
