import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["priceEdit", "price", "modal"];

  toggleAutoPricing() {
    this.priceEditTarget.classList.toggle("hidden");
    this.priceTarget.classList.toggle("hidden");
    if (this.priceTarget.hidden) {
      this.priceEditTarget.focus();
    }
  }
}
