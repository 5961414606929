import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["moneyHuman", "moneyCents"];

  static values = {
    id: String,
  };

  connect() {
    if (!this.idValue) {
      return;
    }

    document.addEventListener(`money-converter:${this.idValue}-changed`, this.toCents.bind(this));
  }

  disconnect() {
    if (!this.idValue) {
      return;
    }

    document.removeEventListener(`money-converter:${this.idValue}-changed`, this.toCents.bind(this));
  }

  toCents() {
    const amount = parseFloat(this.moneyHumanTarget.value) || 0;
    this.moneyCentsTarget.value = (amount * 100).toFixed(0);
  }
}
