import { Controller } from "stimulus";
import { get } from "@rails/request.js";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  static targets = [
    "frame",
    "pricingFrame",
    "startDate",
    "endDate",
    "boatName",
    "boatLoa",
    "boatBeam",
    "boatKind",
    "boatId",
  ];

  connect() {
    this.retrieveFromSession();
    this.refresh();
    this.addCustomDataToForm();
  }

  addCustomDataToForm() {
    if (window.location.href.includes("secure-reservation")) {
      const form = this.element.querySelector("form");
      if (form) {
        const hiddenInput = this.constructor.createHiddenInput("origin", "white_label_secure_reservation");
        form.appendChild(hiddenInput);
      }
    }
  }

  static createHiddenInput(name, value) {
    const hiddenInput = document.createElement("input");
    hiddenInput.type = "hidden";
    hiddenInput.name = name;
    hiddenInput.value = value;
    return hiddenInput;
  }

  retrieveFromSession() {
    const bookingIntent = JSON.parse(sessionStorage.getItem("bookingIntent"));

    if (bookingIntent) {
      this.updateValueFromSession("startDate", "start_date", bookingIntent);
      this.updateValueFromSession("endDate", "end_date", bookingIntent);
      this.updateValueFromSession("boatName", "boat_name", bookingIntent);
      this.updateValueFromSession("boatLoa", "boat_loa_in_m", bookingIntent);
      this.updateValueFromSession("boatBeam", "boat_beam_in_m", bookingIntent);
      this.updateValueFromSession("boatKind", "boat_kind", bookingIntent);

      // trigger price recalculation
      this.refresh();
    }
  }

  updateValueFromSession(target, attribute, bookingIntent) {
    const input = this[`${target}Target`];
    const valueFromSession = bookingIntent[attribute];
    if (!this.constructor.valuePresent(input) && valueFromSession) {
      input.value = valueFromSession;
    }
  }

  storeInSession(e) {
    e.preventDefault();

    sessionStorage.setItem(
      "bookingIntent",
      JSON.stringify(this.newBoatFormData),
    );

    const url = new URL(window.location.href);
    const redirectUrl = new URL(e.target.href);
    redirectUrl.searchParams.append("return-url", url);

    Turbo.visit(redirectUrl);
  }

  changeBookingType(e) {
    e.preventDefault();
    const { bookingType } = e.target.dataset;
    const turboFrame = document.getElementById("new_booking_intent");

    const form = document.querySelector("#booking-intent-form");
    if (!form.checkValidity()) {
      e.preventDefault();
      form.reportValidity();
      return;
    }

    let formData;
    if (this.hasBoatIdTarget) {
      formData = this.existingBoatFormData;
    } else if (this.newBoatPresent) {
      formData = this.newBoatFormData;
    }

    this.constructor.updateTurboFrameSrc(turboFrame, bookingType, formData);
  }

  static updateTurboFrameSrc(turboFrame, bookingType, formData) {
    const srcUrl = new URL(turboFrame.getAttribute("src"));

    Object.keys(formData).forEach((key) => {
      const value = formData[key];
      srcUrl.searchParams.set(`booking_intent[${key}]`, value);
    });

    srcUrl.searchParams.set("booking_intent[booking_type]", bookingType);
    turboFrame.setAttribute("src", srcUrl.toString());
  }

  refresh() {
    if (!this.datesPresent || !this.hasPricingFrameTarget) return;

    let formData;
    if (this.hasBoatIdTarget) {
      formData = this.existingBoatFormData;
    } else if (this.newBoatPresent) {
      formData = this.newBoatFormData;
    }

    if (formData) this.refreshPricingFrame(formData);
  }

  // private

  refreshPricingFrame(formData) {
    const redirectUrl = new URL(this.frameUrl);

    Object.keys(formData).forEach((key) => {
      const value = formData[key];
      redirectUrl.searchParams.append(key, value);
    });

    get(redirectUrl, { responseKind: "turbo-stream" });
    if (this.hasPricingFrameTarget) this.pricingFrameTarget.reload();
  }

  get newBoatPresent() {
    return (
      this.hasBoatBeamTarget && this.hasBoatLoaTarget
    && this.constructor.valuePresent(this.boatBeamTarget)
    && this.constructor.valuePresent(this.boatLoaTarget)
    );
  }

  get datesPresent() {
    return (
      this.hasStartDateTarget && this.hasEndDateTarget
        && this.constructor.valuePresent(this.startDateTarget)
        && this.constructor.valuePresent(this.endDateTarget)
    );
  }

  static valuePresent(input) {
    return input.value.trim().length >= 1;
  }

  get checkedBoatIdTarget() {
    return this.boatIdTargets.find((element) => element.checked);
  }

  get existingBoatFormData() {
    return {
      start_date: this.startDateTarget.value,
      end_date: this.endDateTarget.value,
      boat_id: this.checkedBoatIdTarget.value,
    };
  }

  get newBoatFormData() {
    return {
      start_date: this.startDateTarget.value,
      end_date: this.endDateTarget.value,
      boat_loa_in_m: this.boatLoaTarget.value,
      boat_beam_in_m: this.boatBeamTarget.value,
      boat_name: this.boatNameTarget.value,
      boat_kind: this.boatKindTarget.value,
    };
  }

  get frameUrl() {
    return this.pricingFrameTarget.dataset.src;
  }
}
