/**
 * Creates and appends an option to a select element.
 * @param {HTMLSelectElement} selectElement - The select element to append the option to.
 * @param {string} textContent - The display text of the option.
 * @param {string} value - The value of the option.
 */
export function createOption(selectElement, textContent, value) {
  const option = document.createElement("option");
  option.value = value;
  option.textContent = textContent;
  selectElement.appendChild(option);
}

/**
 * Sets the disabled state of a button.
 * @param {HTMLButtonElement} buttonElement - The button element to disable or enable.
 * @param {boolean} disabled - Whether the button should be disabled.
 */
export function setButtonDisabled(buttonElement, disabled) {
  buttonElement.classList.toggle("disabled", disabled);
  // eslint-disable-next-line no-param-reassign
  buttonElement.disabled = disabled;
}
