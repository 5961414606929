import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["inlinedate", "description", "readbutton", "datepickerright", "map", "interactionbanner"];

  connect() {
  }

  description() {
    const previousText = this.descriptionTarget.innerText;
    const newText = this.descriptionTarget.dataset.description;
    this.descriptionTarget.innerText = newText;
    this.descriptionTarget.dataset.description = previousText;
    const previousReadText = this.readbuttonTarget.innerText;
    const newReadText = this.readbuttonTarget.dataset.description;
    this.readbuttonTarget.innerText = newReadText;
    this.readbuttonTarget.dataset.description = previousReadText;
  }

  // dateChange() {
  //   this.datepickerrightTarget.value = this.inlinedateTarget.value;
  // }

  toggleinteractive() {
    this.mapTarget.classList.remove("mapbox-no-interaction");
    this.interactionbannerTarget.remove();
  }
}
