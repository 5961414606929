import { Controller } from "stimulus";

export default class extends Controller {
  async connect() {
    const { default: tippy } = await import("tippy.js");
    await import("tippy.js/themes/light.css");

    const content = this.element.getAttribute("data-tooltip-content");
    tippy(this.element, {
      content,
      theme: "light",
      allowHTML: true,
    });
  }
}
