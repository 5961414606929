import { Controller } from "stimulus";
import { enter, leave } from "el-transition";

export default class extends Controller {
  static targets = ["container", "backdrop", "panel"];

  connect() {
    document.addEventListener("turbo:submit-end", async (event) => {
      if (event.detail.success) {
        await this.close();
      }
    });
  }

  open() {
    this.containerTarget.classList.remove("hidden");
    enter(this.backdropTarget);
    enter(this.panelTarget);
  }

  async close() {
    return Promise.all([
      leave(this.backdropTarget),
      leave(this.panelTarget),
    ]).then(() => {
      this.containerTarget.classList.add("hidden");
    });
  }
}
