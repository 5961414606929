import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["description"];

  connect() {
  }

  select(event) {
    this.descriptionTarget.innerText = `Berth ${event.currentTarget.dataset.description}`;
  }
}
