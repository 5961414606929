import { Controller } from "stimulus";

export default class extends Controller {
  static values = { hideLauncher: { type: Boolean, default: false } };

  connect() {
    this.updateIntercomSettings();
  }

  updateIntercomSettings() {
    if (typeof window.Intercom !== "undefined") {
      window.Intercom("update", { hide_default_launcher: this.hideLauncherValue });
    } else {
      console.warn("Intercom is not initialized yet.");
    }
  }
}
