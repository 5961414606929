import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["select"];

  redirectToSelectedTab() {
    const selectedOption = this.selectTarget.value;
    window.location.href = selectedOption;
  }
}
