// app/javascript/controllers/note_modal_controller.js

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["modal", "frame"];

  connect() {
    this.modalTarget.classList.add("initialized");
  }

  open(event) {
    event.preventDefault();

    const url = event.currentTarget.href;

    // Show the modal
    this.modalTarget.classList.remove("hidden");
    this.modalTarget.classList.add("flex");
    this.modalTarget.querySelector("h3").focus();
    this.frameTarget.src = url;
  }

  close() {
    // Hide the modal
    this.modalTarget.classList.add("hidden");
    this.modalTarget.classList.remove("flex");

    // Clear the Turbo Frame content
    this.frameTarget.src = "";
    this.frameTarget.innerHTML = `
      <div class="flex justify-center items-center">
        <svg class="animate-spin h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
        </svg>
        <span class="ml-2 text-gray-500">Loading...</span>
      </div>
    `;
  }
}
