import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    document.addEventListener("turbo:load", this.initializeAlpine.bind(this));
  }

  // eslint-disable-next-line class-methods-use-this
  async initializeAlpine() {
    const { init } = await import("alpine-turbo-drive-adapter");
    const Alpine = (await import("alpinejs")).default;
    await import("../components/alpine_components");

    window.Alpine = Alpine;
    init();
    Alpine.start();
  }

  disconnect() {
    document.removeEventListener("turbo:load", this.initializeAlpine);
  }
}
