import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["rowCheckbox", "allCheckbox", "rowActions"];

  toggleRow(event) {
    const checkbox = event.currentTarget;
    this.updateRowStyles(checkbox, checkbox.checked);
    this.updateAllCheckboxState();
  }

  toggleAll() {
    const isChecked = this.allCheckboxTarget.checked;
    this.rowCheckboxTargets.forEach((checkbox) => {
      // eslint-disable-next-line no-param-reassign
      checkbox.checked = isChecked;
      this.updateRowStyles(checkbox, isChecked);
    });
    this.updateAllCheckboxState();
  }

  // Private methods

  // eslint-disable-next-line class-methods-use-this
  updateRowStyles(checkbox, isChecked) {
    const row = checkbox.closest("[data-checkbox-table-row]");
    const rowMainCell = row.querySelector("[data-checkbox-table-row-main-cell]");
    const rowMarker = row.querySelector("[data-checkbox-table-row-marker]");

    row.classList.toggle("bg-gray-50", isChecked);
    rowMainCell.classList.toggle("text-teal-600", isChecked);
    rowMainCell.classList.toggle("text-gray-600", !isChecked);
    rowMarker.classList.toggle("hidden", !isChecked);
  }

  updateAllCheckboxState() {
    const allChecked = this.rowCheckboxTargets.every((checkbox) => checkbox.checked);
    const anyChecked = this.rowCheckboxTargets.some((checkbox) => checkbox.checked);

    this.allCheckboxTarget.checked = allChecked;
    this.allCheckboxTarget.indeterminate = anyChecked && !allChecked;
    this.toggleRowActions(anyChecked);

    this.dispatchSelectedEvent();
  }

  toggleRowActions(anyChecked) {
    const checkedClasses = ["text-gray-900", "bg-teal-600", "text-white", "hover:bg-teal-700"];
    const uncheckedClasses = ["bg-gray-100", "text-gray-500", "pointer-events-none"];

    const actionButton = this.rowActionsTarget.querySelector('[data-billing-charges-index-target="actionButton"]');
    if (anyChecked) {
      actionButton.classList.remove(...uncheckedClasses);
      actionButton.classList.add(...checkedClasses);
    } else {
      actionButton.classList.remove(...checkedClasses);
      actionButton.classList.add(...uncheckedClasses);
    }
  }

  dispatchSelectedEvent() {
    const selectedIds = this.rowCheckboxTargets
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => this.getValueFromCheckbox(checkbox));

    const event = new CustomEvent("checkbox-table:selected", {
      detail: { selectedIds },
      bubbles: true,
    });

    this.element.dispatchEvent(event);
  }

  // eslint-disable-next-line class-methods-use-this
  getValueFromCheckbox(checkbox) {
    const row = checkbox.closest("[data-checkbox-table-row]");
    return row.dataset.checkboxTableValue;
  }
}
