import { Controller } from "stimulus";
import { createOption } from "../utilities";

export default class extends Controller {
  static targets = [
    "typeSelect",
    "serviceReferenceSelect",
    "serviceReferenceLabel",
    "descriptionInput",
    "serviceStartDate",
    "serviceEndDate",
    "berthSelectParent",
    "berthOptionsList",
    "amenitySelectParent",
    "berthSelectInput",
    "berthChargeDescription",
    "serviceReferenceHidden",
    "taxRateHidden",
    "amountAfterTaxLabel",
    "amountAfterTaxInput",
    "amountInput",
    "unitFields",
    "unitsCheckbox",
    "unitNameInput",
    "unitAmountInput",
    "unitQuantityInput",
    "amountCentsHidden",
    "amountAfterTaxCentsHidden",
  ];

  static values = {
    utilities: Object,
    services: Object,
    berths: Object,
    billableStart: String,
    billableEnd: String,
  };

  connect() {
    this.berthOptionsOpen = false;
    this.initializeBerthOptions();

    if (this.typeSelectTarget.value) {
      this.toggleServiceReferenceSelect();
    }
  }

  setSelectableServiceReferences() {
    const selectedType = this.typeSelectTarget.value;

    this.serviceReferenceSelectTarget.disabled = !selectedType;

    const serviceReferences = this.getServiceReferencesForSelectedType();

    this.serviceReferenceSelectTarget.innerHTML = "";
    // TODO[MET-129]: Use hidden toggle instead of text in JS
    createOption(this.serviceReferenceSelectTarget, selectedType ? `Select ${selectedType}...` : "", "");
    serviceReferences.forEach(
      ([label, id]) => createOption(this.serviceReferenceSelectTarget, label, id),
    );

    this.toggleServiceReferenceSelect();
  }

  setFieldsFromServiceReference() {
    if (!this.isBerthTypeSelected) {
      this.serviceReferenceHiddenTarget.value = this.serviceReferenceSelectTarget.value;
    }

    const selected = this.getSelectedServiceReference();
    if (!selected) return;

    const description = selected?.description || "";
    const taxRate = selected?.tax_rate || 0;
    const taxPercent = (taxRate * 100).toFixed(1);

    // Update the form fields and labels
    if (this.isBerthTypeSelected) {
      const berthChargeDescription = this.hasBerthChargeDescriptionTarget ? this.berthChargeDescriptionTarget.value : "";
      this.descriptionInputTarget.value = description ? `${berthChargeDescription} ${description}` : "";
    } else {
      this.descriptionInputTarget.value = description || "";
    }
    // TODO[MET-129]: Use hidden toggle instead of text in JS
    this.amountAfterTaxLabelTarget.textContent = `Price with Tax (${taxPercent}% tax rate)`;
    this.taxRateHiddenTarget.value = taxRate;

    this.setUnitFieldsFromServiceReference(selected);
    this.setTotalAmounts();
  }

  setServiceDates() {
    if (this.isBerthTypeSelected) {
      // eslint-disable-next-line no-underscore-dangle
      const startDatePicker = this.serviceStartDateTarget._flatpickr;
      // eslint-disable-next-line no-underscore-dangle
      const endDatePicker = this.serviceEndDateTarget._flatpickr;

      if (startDatePicker && endDatePicker) {
        startDatePicker.setDate(this.billableStartValue, false);
        endDatePicker.setDate(this.billableEndValue, false);
      }
    }
  }

  setUnitFieldsFromServiceReference(selected) {
    if (!selected || !this.hasUnits) return;

    this.unitNameInputTarget.value = selected.unit_name || null;
    this.unitAmountInputTarget.value = (selected.unit_price_cents || 0) / 100;
    this.unitQuantityInputTarget.value = 1;

    this.dispatchMoneyConverterEvent("unit-price");
  }

  getSelectedServiceReference() {
    const id = this.serviceReferenceHiddenTarget.value;
    if (!id) {
      return undefined;
    }

    switch (this.typeSelectTarget.value) {
      case "Utility": return this.utilitiesValue[id];
      case "Service": return this.servicesValue[id];
      case "Berth": return this.berthsValue[id];
      default: return undefined;
    }
  }

  getServiceReferencesForSelectedType() {
    switch (this.typeSelectTarget.value) {
      case "Utility": return this.toOptionsForSelect(this.utilitiesValue);
      case "Service": return this.toOptionsForSelect(this.servicesValue);
      case "Berth": return this.toOptionsForSelect(this.berthsValue);
      default: return [];
    }
  }

  toggleServiceReferenceSelect() {
    this.berthSelectParentTarget.classList.toggle("hidden", !this.isBerthTypeSelected);
    this.amenitySelectParentTarget.classList.toggle("hidden", this.isBerthTypeSelected);
  }

  toggleBerthOptions() {
    this.berthOptionsListTarget.classList.toggle("hidden", this.berthOptionsOpen);
    this.berthOptionsOpen = !this.berthOptionsOpen;
  }

  toggleUnitFields() {
    const { hasUnits } = this;
    this.unitFieldsTarget.classList.toggle("hidden", !hasUnits);
    this.amountInputTarget.disabled = hasUnits;
    this.amountAfterTaxInputTarget.disabled = hasUnits;

    const selected = this.getSelectedServiceReference();

    if (hasUnits && selected && this.areUnitFieldsEmpty) {
      this.setUnitFieldsFromServiceReference(selected);
    }

    this.setTotalAmounts();
  }

  setTotalAmount() {
    if (!this.hasUnits) return;

    const unitPrice = parseFloat(this.unitAmountInputTarget.value || 0, 10);
    const quantity = parseFloat(this.unitQuantityInputTarget.value || 1, 10);

    this.amountInputTarget.value = (unitPrice * quantity).toFixed(2);
    this.dispatchMoneyConverterEvent("price-without-tax");
  }

  setTotalAmountAfterTax() {
    const baseAmount = parseFloat(this.amountInputTarget.value) || 0;
    const taxRate = parseFloat(this.taxRateHiddenTarget.value || 0);

    if (Number.isNaN(baseAmount)) {
      this.amountAfterTaxInputTarget.value = "";
      this.amountCentsHiddenTarget.value = "";
      this.amountAfterTaxCentsHiddenTarget.value = "";
    } else {
      const baseAmountCents = Math.round(baseAmount * 100) || 0;
      const amountAfterTaxCents = Math.round(baseAmountCents * (1 + taxRate)) || 0;
      this.amountAfterTaxInputTarget.value = (amountAfterTaxCents / 100).toFixed(2);
      this.amountCentsHiddenTarget.value = baseAmountCents;
      this.amountAfterTaxCentsHiddenTarget.value = amountAfterTaxCents;
    }
  }

  setBaseAmountFromTotal() {
    const amountAfterTax = parseFloat(this.amountAfterTaxInputTarget.value) || 0;
    const taxRate = parseFloat(this.taxRateHiddenTarget.value || 0);

    if (Number.isNaN(amountAfterTax)) {
      this.amountInputTarget.value = "";
      this.amountCentsHiddenTarget.value = "";
      this.amountAfterTaxCentsHiddenTarget.value = "";
    } else {
      const amountAfterTaxCents = Math.round(amountAfterTax * 100) || 0;
      const baseAmountCents = Math.round(amountAfterTaxCents / (1 + taxRate)) || 0;
      this.amountInputTarget.value = (baseAmountCents / 100).toFixed(2);
      this.amountCentsHiddenTarget.value = baseAmountCents;
      this.amountAfterTaxCentsHiddenTarget.value = amountAfterTaxCents;
    }
  }

  setTotalAmounts() {
    this.setTotalAmount();
    this.setTotalAmountAfterTax();
  }

  onFocusBerthSelectInput() {
    if (!this.serviceReferenceHiddenTarget.value) {
      this.berthSelectInputTarget.value = "";
    }
    this.toggleBerthOptions();
  }

  onBlurBerthSelectInput(event) {
    if (event?.relatedTarget?.attributes?.["data-berth-option-value"]) return;

    const selectedServiceReferenceId = this.serviceReferenceHiddenTarget.value;

    if (selectedServiceReferenceId) {
      this.berthSelectInputTarget.value = this.berthsValue[selectedServiceReferenceId].label;
    } else {
    // TODO[MET-129]: Use hidden toggle instead of text in JS
      this.berthSelectInputTarget.value = "Select Berth...";
    }

    this.toggleBerthOptions();
    this.filterBerthOptions("");
  }

  onInputBerthSelectInput(event) {
    const searchTerm = event.target.value.toLowerCase();
    this.filterBerthOptions(searchTerm);
  }

  // eslint-disable-next-line class-methods-use-this
  toOptionsForSelect(data) {
    return Object.values(data).map(({ label, value }) => [label, value]);
  }

  initializeBerthOptions() {
    this.berthOptionsListTarget.querySelectorAll("li").forEach((option) => {
      option.addEventListener("mouseenter", () => this.highlightBerthOption(option, true));
      option.addEventListener("mouseleave", () => this.highlightBerthOption(option, false));
      option.addEventListener("click", () => this.selectBerthOption(option));

      if (this.serviceReferenceHiddenTarget.value === option.getAttribute("data-berth-option-value")) {
        this.styleBerthOption(option, true);
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  highlightBerthOption(option, highlight) {
    const checkmark = option.querySelector(".berth-option-checkmark");
    if (highlight) {
      option.classList.add("text-white", "bg-teal-600");
      checkmark.classList.add("text-white");
    } else {
      option.classList.remove("text-white", "bg-teal-600");
      checkmark.classList.remove("text-white");
    }
  }

  selectBerthOption(option) {
    const label = option.querySelector(".truncate").textContent;
    const value = option.getAttribute("data-berth-option-value");

    this.berthSelectInputTarget.value = label;
    this.serviceReferenceHiddenTarget.value = value;

    this.setFieldsFromServiceReference();
    this.toggleBerthOptions();

    this.berthOptionsListTarget.querySelectorAll("li").forEach((o) => {
      this.styleBerthOption(o, o === option);
    });
  }

  // eslint-disable-next-line class-methods-use-this
  styleBerthOption(option, isSelected) {
    option.querySelector(".berth-option-label").classList.toggle("font-semibold", isSelected);
    option.querySelector(".berth-option-checkmark").classList.toggle("hidden", !isSelected);
  }

  filterBerthOptions(searchTerm) {
    let matchFound = false;

    this.hideNoMatchFoundMessage();

    this.berthOptionsListTarget.querySelectorAll("li").forEach((option) => {
      const optionText = option.textContent.toLowerCase();
      const isVisible = !searchTerm || optionText.includes(searchTerm);
      // eslint-disable-next-line no-param-reassign
      option.style.display = isVisible ? "block" : "none";
      if (isVisible) matchFound = true;
    });

    if (!matchFound) {
      this.showNoMatchFoundMessage();
    }
  }

  showNoMatchFoundMessage() {
    if (!this.berthOptionsListTarget.querySelector(".no-match")) {
      const noMatchElement = document.createElement("li");
      noMatchElement.classList.add("no-match", "text-gray-900", "px-3", "py-2");
      noMatchElement.textContent = "No matches found";
      noMatchElement.style.cursor = "default";
      this.berthOptionsListTarget.appendChild(noMatchElement);
    }
  }

  hideNoMatchFoundMessage() {
    const noMatchElement = this.berthOptionsListTarget.querySelector(".no-match");
    if (noMatchElement) {
      noMatchElement.remove();
    }
  }

  dispatchMoneyConverterEvent(eventId) {
    const event = new CustomEvent(`money-converter:${eventId}-changed`, { bubbles: true });
    this.element.dispatchEvent(event);
  }

  get hasUnits() {
    return this.unitsCheckboxTarget.checked;
  }

  get isBerthTypeSelected() {
    return this.typeSelectTarget.value === "Berth";
  }

  get areUnitFieldsEmpty() {
    return !this.unitNameInputTarget.value
      && !this.unitAmountInputTarget.value
      && !parseInt(this.unitQuantityInputTarget.value, 10);
  }
}
