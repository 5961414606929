import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["listContainer", "noItems"];

  async connect() {
    const List = (await import("list.js")).default;
    const options = {
      valueNames: this.data.get("valueNames").split(","),
    };
    this.listInstance = new List(this.element, options);
    this.listInstance.on("updated", () => this.toggleNoItemsMessage());
  }

  toggleNoItemsMessage() {
    const hasItems = this.listInstance.visibleItems.length > 0;
    this.noItemsTarget.classList.toggle("hidden", hasItems);
    this.listContainerTarget.classList.toggle("hidden", !hasItems);
  }
}
