import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["rowCheckbox", "allCheckbox", "rowActions"];

  toggleRow(event) {
    const checkbox = event.currentTarget;
    this.constructor.updateRowStyles(checkbox, checkbox.checked);
    this.updateAllCheckboxState();
  }

  toggleAll() {
    const isChecked = this.allCheckboxTarget.checked;
    this.rowCheckboxTargets.forEach((cb) => {
      const checkbox = cb;
      checkbox.checked = isChecked;
      this.constructor.updateRowStyles(checkbox, isChecked);
    });
    this.updateAllCheckboxState();
  }

  // Private methods

  static updateRowStyles(checkbox, isChecked) {
    const row = checkbox.closest("[data-shared--tables--checkbox-table-row]");
    const rowMainCell = row.querySelector("[data-shared--tables--checkbox-table-row-main-cell]");
    const rowMarker = row.querySelector("[data-shared--tables--checkbox-table-row-marker]");

    if (isChecked) {
      row.classList.add("bg-gray-50");
      if (rowMainCell) {
        rowMainCell.classList.add("text-teal-600");
        rowMainCell.classList.remove("text-gray-600");
      }
      if (rowMarker) {
        rowMarker.classList.remove("hidden");
      }
    } else {
      row.classList.remove("bg-gray-50");
      if (rowMainCell) {
        rowMainCell.classList.remove("text-teal-600");
        rowMainCell.classList.add("text-gray-600");
      }
      if (rowMarker) {
        rowMarker.classList.add("hidden");
      }
    }
  }

  updateAllCheckboxState() {
    const allChecked = this.rowCheckboxTargets.every((checkbox) => checkbox.checked);
    const anyChecked = this.rowCheckboxTargets.some((checkbox) => checkbox.checked);

    this.allCheckboxTarget.checked = allChecked;
    this.allCheckboxTarget.indeterminate = anyChecked && !allChecked;
    this.toggleRowActions(anyChecked);

    this.dispatchSelectedEvent();
  }

  toggleRowActions(anyChecked) {
    const checkedClasses = ["text-gray-900", "bg-teal-600", "text-white", "hover:bg-teal-700"];
    const uncheckedClasses = ["bg-gray-100", "text-gray-500", "pointer-events-none"];

    const actionButton = this.rowActionsTarget.querySelector("button");

    if (actionButton) {
      if (anyChecked) {
        // Enable the action button
        actionButton.disabled = false;
        actionButton.classList.remove(...uncheckedClasses);
        actionButton.classList.add(...checkedClasses);
      } else {
        // Disable the action button
        actionButton.disabled = true;
        actionButton.classList.remove(...checkedClasses);
        actionButton.classList.add(...uncheckedClasses);
      }
    }
  }

  dispatchSelectedEvent() {
    const selectedIds = this.rowCheckboxTargets
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => this.constructor.getValueFromCheckbox(checkbox));

    const event = new CustomEvent("select-all-checkbox-table:selected", {
      detail: { selectedIds },
      bubbles: true,
    });

    this.element.dispatchEvent(event);
  }

  static getValueFromCheckbox(checkbox) {
    const row = checkbox.closest("[data-shared--tables--checkbox-table-row]");
    return row.dataset.selectAllCheckboxTableValue;
  }

  // Optional: Handle the bulk action button click
  // eslint-disable-next-line class-methods-use-this
  performBulkAction() {
    console.log("Bulk action triggered");
    // Proceed with your bulk action logic
  }
}
