import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["close", "button", "input", "link", "radio"];

  connect() {
    if (this.hasCloseTarget) {
      this.hide();
    }
  }

  hide() {
    setTimeout(() => {
      this.closeTarget.click();
    }, 10000);
  }

  radioSelect() {
    this.radioTarget.click();
  }

  toggle() {
    this.inputTarget.value === "true"
      ? (this.inputTarget.value = false)
      : (this.inputTarget.value = true);
  }

  changeInputToTrue() {
    this.inputTarget.value = "true";
  }

  changeInputToFalse() {
    this.inputTarget.value = "false";
  }

  navigate() {
    this.linkTarget.click();
  }
}
