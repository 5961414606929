import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["chart"];

  chartInstance = null;

  async connect() {
    try {
      const ChartModule = await import("chart.js/auto");
      this.initializeChart(ChartModule.default);
    } catch (error) {
      console.error("Failed to load Chart.js", error);
    }
  }

  initializeChart(Chart) {
    const ctx = this.chartTarget.getContext("2d");
    const chartData = this.chartData();

    if (this.chartInstance) {
      this.chartInstance.destroy();
    }

    this.chartInstance = new Chart(ctx, {
      type: "line",
      data: chartData,
      options: this.constructor.chartOptions(),
    });
  }

  chartData() {
    return {
      labels: JSON.parse(this.chartTarget.dataset.labels),
      datasets: [{
        data: JSON.parse(this.chartTarget.dataset.data),
        backgroundColor: "rgba(246, 210, 199, 0.20)",
        fill: true,
        borderColor: "#fc927c",
        borderWidth: 2,
        tension: 0,
        pointRadius: 2,
        pointHoverRadius: 4,
        pointBackgroundColor: "#fc927c",
      }],
    };
  }

  static chartOptions() {
    return {
      responsive: true,
      plugins: {
        legend: { display: false },
      },
      layout: {
        padding: {
          left: 20, right: 35, top: 20, bottom: 20,
        },
      },
      scales: {
        y: this.yScaleOptions(),
        x: this.xScaleOptions(),
      },
    };
  }

  static yScaleOptions() {
    return {
      beginAtZero: true,
      max: 100,
      ticks: {
        stepSize: 10,
        callback(value) { return `${value} %`; },
        color: "#a0aec0",
        font: { size: 12 },
      },
      grid: {
        color: "#e2e8f0",
        lineWidth: 1,
      },
    };
  }

  static xScaleOptions() {
    return {
      ticks: {
        color: "#a0aec0",
        font: { size: 12 },
      },
      grid: {
        display: false,
      },
    };
  }
}
