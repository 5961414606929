import { Controller } from "stimulus";
import { post } from "@rails/request.js";

/**
 * Stimulus controller for embedding a Superset dashboard or chart using the Superset Embedded SDK.
 *
 * Example:
 * <div data-controller="superset-embed"
 *      data-superset-embed-resource-id-value="cd24f014-e247-4304-9818-6d9274a47e0b"
 *      data-superset-embed-resource-type-value="dashboard" <!-- Can be "dashboard" or "chart" -->
 *      data-superset-embed-superset-domain-value="https://superset.metarina.com"
 *      data-superset-embed-hide-title-value="true"
 *      data-superset-embed-expanded-filters-value="false"
 *      data-superset-embed-iframe-sandbox-extras-value="allow-top-navigation">
 * </div>
 */

export default class extends Controller {
  static values = {
    resourceId: String,
    resourceType: { type: String, default: "dashboard" },
    supersetDomain: String,
    hideTitle: { type: Boolean, default: true },
    expandedFilters: { type: Boolean, default: false },
    iframeSandboxExtras: { type: String, default: "allow-same-origin allow-scripts allow-popups" },
  };

  async connect() {
    const { embedDashboard, embedChart } = await import("@superset-ui/embedded-sdk");

    // Determine the embedding function based on the resource type
    const embedFunction = this.resourceTypeValue === "dashboard" ? embedDashboard : embedChart;
    const guestToken = await this.fetchGuestToken();

    // Embed the resource (either dashboard or chart)
    const resource = await embedFunction({
      id: this.resourceIdValue,
      supersetDomain: this.supersetDomainValue,
      mountPoint: this.element,
      fetchGuestToken: () => Promise.resolve(guestToken),
      dashboardUiConfig: this.resourceTypeValue === "dashboard" ? {
        hideTitle: this.hideTitleValue,
        filters: { visible: false, expanded: false }, // this.expandedFiltersValue
        // TODO: https://github.com/apache/superset/pull/29422/files
      } : undefined, // Only apply UI config for dashboards
      iframeSandboxExtras: this.iframeSandboxExtrasValue.split(" "), // Set iframe sandbox options dynamically
    });

    // Adjust the iframe height based on the resource's scroll size
    this.adjustIframeHeight(resource);
  }

  async fetchGuestToken() {
    const response = await post("/superset/tokens", {
      body: JSON.stringify({
        resource_id: this.resourceIdValue,
        resource_type: this.resourceTypeValue,
      }),
      responseKind: "json",
    });

    if (response.ok) {
      const data = await response.json;
      return data.token;
    }
    console.error("Failed to fetch guest token:", response.statusText);
    return null;
  }

  async adjustIframeHeight(resource) {
    const { height } = await resource.getScrollSize();
    const iframe = this.element.querySelector("iframe");

    if (iframe) {
      iframe.style.height = `${height}px`;
    }

    // Set up an interval for dynamic height adjustment if content changes
    setInterval(async () => {
      const { height: newHeight } = await resource.getScrollSize();
      if (iframe && iframe.style.height !== `${newHeight}px`) {
        iframe.style.height = `${newHeight}px`;
      }
    }, 1000);
  }
}
