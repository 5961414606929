import { Controller } from "stimulus";
import List from "list.js";

const LIST_JS_OPTIONS = {
  valueNames: ["first_name", "last_name", "full_name"],
};

export default class extends Controller {
  static targets = ["search", "customerOption", "select", "searchOptions", "form", "customerId"];

  connect() {
    this.listInstance = new List("search_customers", LIST_JS_OPTIONS);
    this.listInstance.on("updated", () => this.showNoCustomersFoundMessage());

    if (this.customerIdTarget.dataset.initialValue) {
      this.customerIdTarget.value = this.customerIdTarget.dataset.initialValue;
    }

    if (this.searchTarget.dataset.initialValue) {
      this.searchTarget.value = this.searchTarget.dataset.initialValue;
    }
  }

  selectCustomer(event) {
    event.preventDefault();

    this.searchTarget.value = event.currentTarget.dataset.name;
    this.customerIdTarget.value = event.currentTarget.dataset.id;
    this.searchOptionsTarget.style.display = "none";
    this.formTarget.submit();
  }

  showNoCustomersFoundMessage() {
    const noMatchElement = this.element.querySelector(".no-match");
    const searchOptionsElement = this.searchOptionsTarget;
    if (this.listInstance.visibleItems.length === 0) {
      noMatchElement.classList.remove("hidden");
      searchOptionsElement.classList.add("hidden");
    } else {
      noMatchElement.classList.add("hidden");
      searchOptionsElement.classList.remove("hidden");
    }
  }
}
