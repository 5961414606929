import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["emailCheckboxSection"];

  connect() {
    this.toggleVisibility();
  }

  toggleVisibility() {
    const provider = this.element.querySelector('input[type="radio"]:checked').value;
    const shouldHide = provider === "metarina";
    this.emailCheckboxSectionTargets.forEach((element) => {
      element.classList.toggle("hidden", shouldHide);
    });
  }
}
