import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["baseAmount", "amountWithTax"];

  static values = {
    taxRate: Number,
  };

  connect() {
    this.element.addEventListener("tax-calculator:tax-rate-changed", this.handleTaxRateChanged);
  }

  disconnect() {
    this.element.removeEventListener("tax-calculator:tax-rate-changed", this.handleTaxRateChanged);
  }

  calculateWithTax() {
    const baseAmount = parseFloat(this.baseAmountTarget.value) || 0;
    const amountWithTax = baseAmount * (1 + this.taxRateValue);

    this.amountWithTaxTarget.value = amountWithTax.toFixed(2);
  }

  handleTaxRateChanged = (event) => {
    const { taxRate } = event.detail;
    this.taxRateValue = taxRate;
  };

  taxRateValueChanged() {
    this.calculateWithTax();
  }
}
