// Async function to initialize the map on the index page
const initMapboxIndex = async () => {
  const mapElement = document.getElementById("map_marina_index");

  if (mapElement) {
    const mapboxgl = await import("mapbox-gl");
    await import("mapbox-gl/dist/mapbox-gl.css");
    const map = new mapboxgl.Map({
      accessToken: mapElement.dataset.mapboxApiKey,
      container: "map_marina_index",
      style: "mapbox://styles/leon-metarina/ckmmkrrci6u6k17nycfsa5tv8?optimize=true",
    });
    map.addControl(new mapboxgl.NavigationControl(), "bottom-right");

    const markers = JSON.parse(mapElement.dataset.markers);
    const features = markers.map((marker) => ({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [marker.lng, marker.lat],
      },
      properties: {
        popup: marker.infoWindow,
      },
    }));

    const fitMapToMarkers = (targetMap, targetMarkers) => {
      const bounds = new mapboxgl.LngLatBounds();
      targetMarkers.forEach((marker) => bounds.extend([marker.lng, marker.lat]));
      targetMap.fitBounds(bounds, { padding: 70, maxZoom: 15, duration: 0 });
    };

    fitMapToMarkers(map, markers);

    map.on("load", () => {
      map.addSource("marinas", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features,
        },
        cluster: true,
        clusterMaxZoom: 15,
        clusterRadius: 50,
      });

      map.addLayer({
        id: "clusters",
        type: "circle",
        source: "marinas",
        filter: ["has", "point_count"],
        paint: {
          "circle-color": [
            "step",
            ["get", "point_count"],
            "#fc927c",
            100,
            "#fc927c",
            750,
            "#fc927c",
          ],
          "circle-radius": [
            "step",
            ["get", "point_count"],
            20,
            100,
            30,
            750,
            40,
          ],
        },
      });

      map.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: "marinas",
        filter: ["has", "point_count"],
        layout: {
          "text-field": "{point_count_abbreviated}",
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": 12,
        },
        paint: {
          "text-color": "#ffffff",
        },
      });

      map.addLayer({
        id: "unclustered-point",
        type: "circle",
        source: "marinas",
        filter: ["!", ["has", "point_count"]],
        paint: {
          "circle-color": "#fc927c",
          "circle-radius": 6,
          "circle-stroke-width": 1,
          "circle-stroke-color": "#fff",
        },
      });

      map.on("click", "clusters", (e) => {
        const features = map.queryRenderedFeatures(e.point, { layers: ["clusters"] });
        const clusterId = features[0].properties.cluster_id;
        map.getSource("marinas").getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return;

          map.easeTo({
            center: features[0].geometry.coordinates,
            zoom,
          });
        });
      });

      map.on("click", "unclustered-point", (e) => {
        const coordinates = e.features[0].geometry.coordinates.slice();
        new mapboxgl.Popup({ closeButton: false })
          .setLngLat(coordinates)
          .setHTML(e.features[0].properties.popup)
          .addTo(map);
      });

      map.on("mouseenter", "clusters", () => { map.getCanvas().style.cursor = "pointer"; });
      map.on("mouseleave", "clusters", () => { map.getCanvas().style.cursor = ""; });
    });
  }
};

// Async function to initialize the map on the show page
const initMapboxShow = async () => {
  const mapElement = document.getElementById("map_marina_show");

  if (mapElement) {
    const mapboxgl = await import("mapbox-gl");
    await import("mapbox-gl/dist/mapbox-gl.css");

    const map = new mapboxgl.Map({
      accessToken: mapElement.dataset.mapboxApiKey,
      container: "map_marina_show",
      style: "mapbox://styles/leon-metarina/ckmmkrrci6u6k17nycfsa5tv8?optimize=true",
    });
    map.addControl(new mapboxgl.NavigationControl(), "bottom-right");

    const marker = JSON.parse(mapElement.dataset.markers);
    new mapboxgl.Marker({ color: "#fc9d87" })
      .setLngLat([marker.lng, marker.lat])
      .addTo(map);

    const fitMapToMarkers = (targetMap, targetMarker) => {
      const bounds = new mapboxgl.LngLatBounds();
      bounds.extend([targetMarker.lng, targetMarker.lat]);
      targetMap.fitBounds(bounds, { padding: 70, maxZoom: 15, duration: 0 });
    };

    fitMapToMarkers(map, marker);
  }
};

export { initMapboxIndex, initMapboxShow };
