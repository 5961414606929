import * as ActiveStorage from "@rails/activestorage";
import { Turbo } from "@hotwired/turbo-rails";

// Lazy loading for images.
import "lazysizes";

// Import external libraries required globally.
import "trix";
import "@rails/actiontext";
import "fslightbox";
import "../controllers/index";
import { initMapboxIndex, initMapboxShow } from "../plugins/init_mapbox";

// Initialize Rails UJS and ActiveStorage, and set global variables.
ActiveStorage.start();

// https://github.com/hotwired/turbo-rails/pull/367#issuecomment-1934729149
// https://github.com/corsego/151-dialog-turbo-modals/commit/c2cecbae1da1e327f8ad91c403531a8ec738a3d1#diff-732faaf698b62ae82d14ed1df9d1505d973a27281fe5d887a935d370144343b4
// turbo_stream.action(:redirect, comments_path)
Turbo.StreamActions.redirect = function () {
  Turbo.visit(this.target);
};

// Setup event listeners for Turbo Drive and custom events.
document.addEventListener("turbo:load", () => {
  initMapboxIndex();
  initMapboxShow();
});

document.addEventListener("trix-file-accept", (e) => {
  e.preventDefault();
});
