import { Controller } from "stimulus";
import flatpickr from "flatpickr";

export default class extends Controller {
  static targets = ["start", "end", "flatpickr"];

  connect() {
    const startDate = this.startTarget.value;
    const endDate = this.endTarget.value;
    const defaultDate = [];

    if (startDate) {
      defaultDate.push(startDate);
    }
    if (endDate) {
      defaultDate.push(endDate);
    }

    const locale = this.data.get("locale") || "en";

    this.fp = flatpickr(this.flatpickrTarget, {
      inline: true,
      mode: "range",
      dateFormat: "Y-m-d",
      defaultDate,
      locale,
      onChange: (selectedDates) => {
        this.startTarget.value = selectedDates[0] ? this.formatDate(selectedDates[0]) : "";
        this.endTarget.value = selectedDates[1] ? this.formatDate(selectedDates[1]) : "";
      },
    });
    this.fp.close();
  }

  disconnect() {
    this.fp.destroy();
  }

  // eslint-disable-next-line class-methods-use-this
  formatDate(date) {
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();

    if (month.length < 2) {
      month = `0${month}`;
    }
    if (day.length < 2) {
      day = `0${day}`;
    }

    return `${year}-${month}-${day}`;
  }
}
